import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ContatoService {
  private baseUrl: string

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrlApi
  }

  enviar(jsonData: any) {
    return this.http.post(`${this.baseUrl}/publico/contato`, jsonData, { responseType: 'text' }).pipe(
      map(resultado => {
        return resultado
      })
    )
  }
}
