import { Component, OnInit, inject } from '@angular/core'
import { Recurso } from 'src/app/models/recurso'
import { RecursoService } from 'src/app/services/recurso.service'
import { DownloadService } from '../../../services/download.service'

@Component({
  selector: 'app-recurso-listar',
  templateUrl: './recurso-listar.component.html',
  styleUrls: ['./recurso-listar.component.css'],
})
export class RecursoListarComponent implements OnInit {
  recursos: Recurso[] = []
  loading: boolean = true
  recurso: Recurso | undefined

  constructor(private recursoService: RecursoService, private downloadService: DownloadService) {}

  ngOnInit(): void {
    this.recursoService.listar().subscribe(recursos => {
      this.recursos = recursos
      this.loading = false
    })
  }

  openParecer(): void {
    this.downloadService.anexoParecer(this.recurso?.id ?? 0)
  }
}
