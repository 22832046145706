import { Component, Input, OnInit } from '@angular/core'
import { BoletoModalService } from '../services/boleto-modal.service'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { BoletoPreview } from '../models/boleto-preview'

@Component({
  selector: 'app-boleto-modal',
  templateUrl: './boleto-modal.component.html',
  styleUrls: ['./boleto-modal.component.css'],
})
export class BoletoModalComponent implements OnInit {
  boletoPreview?: BoletoPreview
  exibir: boolean = false
  @Input() trustedUrl!: SafeUrl
  windowHeight: number = 400

  constructor(private sanitizer: DomSanitizer, private boletoModalService: BoletoModalService) {}

  ngOnInit(): void {
    this.windowHeight = window.innerHeight - 200
    this.subscribeToModal()
  }

  subscribeToModal() {
    this.boletoModalService.boletoModalEventsArquivo.subscribe(boletoPreview => {
      this.boletoPreview = boletoPreview
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(boletoPreview.url)
      this.toggle()
    })
  }

  toggle(): void {
    this.exibir = !this.exibir
  }
}
