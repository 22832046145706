import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { FilePreview } from '../models/file-preview'

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modalEventsEnviar: Observable<boolean>
  modalEventsArquivo: Observable<FilePreview>
  private _modalEventsEnviar = new Subject<boolean>()
  private _modalEventsArquivo = new Subject<FilePreview>()

  constructor() {
    this.modalEventsEnviar = this._modalEventsEnviar.asObservable()
    this.modalEventsArquivo = this._modalEventsArquivo.asObservable()
  }

  showPreview(file: File, title: string, subtitle: string): void {
    this._modalEventsArquivo.next({
      file,
      title,
      subtitle,
    })
  }

  enviar(result: boolean): void {
    this._modalEventsEnviar.next(result)
  }
}
