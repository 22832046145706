import { Component, OnInit } from '@angular/core'
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { filter } from 'rxjs'
// npm install @datadog/browser-rum
// import { datadogRum } from '@datadog/browser-rum'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title: string = 'Sistema de inscrições'

  constructor(private swUpdate: SwUpdate) {}

  ngOnInit(): void {
    // datadogRum.init({
    //   applicationId: 'c112082f-5a2b-4a13-814b-463c3ee57b85',
    //   clientToken: 'pube0726a5498995dfd18293af99b7beaf0',
    //   site: 'datadoghq.com',
    //   service:'sinsc-dev',
    //   env:'dev',
    //   sampleRate: 100,
    //   sessionReplaySampleRate: 20,
    //   trackInteractions: true,
    //   trackResources: true,
    //   trackLongTasks: true,
    //   defaultPrivacyLevel:'mask-user-input'
    // })
    // datadogRum.startSessionReplayRecording()
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(evt => {
          confirm('Nova versão do sistema disponível. Deseja atualizar?')
          document.location.reload()
        })
    }
  }
}
