import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { identity, Observable } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { environment } from '../../environments/environment'
import { InscricaoComplementoSituacao } from '../models/inscricao-complemento-situacao'
import { InscricaoComplemento } from '../models/inscricao-complemento'

@Injectable({
  providedIn: 'root',
})
export class InscricaoComplementoService {
  private baseUrl: string

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {
    this.baseUrl = environment.baseUrlApi
  }

  pendencia(id: number): Observable<InscricaoComplementoSituacao> {
    return this.http
      .get<InscricaoComplementoSituacao>(`${this.baseUrl}/privado/candidato/inscricao/${id}/complemento/pendencia`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  upload(idInscricao: number, id: number, formData: FormData): Observable<InscricaoComplemento> {
    return this.http
      .post<InscricaoComplemento>(
        `${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/complemento/${id}/upload`,
        formData
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  checkbox(idInscricao: number, id: number, formData: FormData): Observable<InscricaoComplemento> {
    return this.http
      .post<InscricaoComplemento>(
        `${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/complemento/${id}/checkbox`,
        formData
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  notificaCandidatoSobreAnexos(idInscricao: number, jsonData: any): Observable<InscricaoComplementoSituacao> {
    return this.http
      .post<InscricaoComplementoSituacao>(
        `${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/complemento/notifica-candidato-anexos/`,
        jsonData
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  verificaPeriodoEnvio(idInscricao: number, jsonData: any): Observable<InscricaoComplementoSituacao> {
    return this.http
      .post<InscricaoComplementoSituacao>(
        `${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/complemento/verifica-periodo-envio/`,
        jsonData
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
