import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor() {}

  formatToUs(data: string): string {
    if (data == null || data == '' || data == undefined) {
      return ''
    }
    if (!data.includes('/') && data.includes('-')) {
      return data
    }
    const dataSplit = data.split('/')
    return dataSplit[2] + '-' + dataSplit[1] + '-' + dataSplit[0]
  }

  formatToBr(data: string): string {
    if (data == null || data == '' || data == undefined) {
      return ''
    }
    if (!data.includes('-') && data.includes('/')) {
      return data
    }
    const dataSplit = data.split('-')
    return dataSplit[2] + '/' + dataSplit[1] + '/' + dataSplit[0]
  }

  dateIsValid(dateStr: string): boolean {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/
    if (dateStr.match(regex) === null) {
      return false
    }
    const [day, month, year] = dateStr.split('/')
    const isoFormattedStr = `${year}-${month}-${day}`
    const date = new Date(isoFormattedStr)
    const timestamp = date.getTime()
    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return false
    }
    return date.toISOString().startsWith(isoFormattedStr)
  }
}
