import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { UsuarioService } from 'src/app/services/usuario.service'
import { ToastService } from '../../toast/services/toast.service'

@Component({
  selector: 'app-senha-redefinir',
  templateUrl: './senha-redefinir.component.html',
  styleUrls: ['./senha-redefinir.component.css'],
})
export class SenhaRedefinirComponent implements OnInit {
  codigo: string = ''
  senhaPattern: string = '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d).{8,}$'

  form = new FormGroup({
    novaSenha: new FormControl('', [Validators.required, Validators.pattern(this.senhaPattern)]),
    novaSenhaConfirmacao: new FormControl('', [Validators.required, Validators.pattern(this.senhaPattern)]),
  })

  constructor(
    private usuarioService: UsuarioService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.codigo = params['codigo']
    })
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  alterar() {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast('Atenção', 'O formulário não foi preenchido corretamente')
      return
    }

    let novaSenha = this.form.get('novaSenha')?.value ?? ''
    let novaSenhaConfirmacao = this.form.get('novaSenhaConfirmacao')?.value ?? ''
    if (novaSenha !== novaSenhaConfirmacao) {
      this.toastService.showErrorToast('Atenção', 'A Nova Senha e a Confirmação não são iguais')
    } else {
      var jsonData = {
        codigo: this.codigo,
        senha: this.form.get('novaSenha')?.value,
      }
      this.usuarioService.senhaRedefinir(jsonData).subscribe(resultado => {
        if (resultado == 'updated') {
          this.toastService.showSuccessToast('Atenção', 'Sua senha foi alterado com sucesso')
          this.form.reset()
          this.router.navigate(['/sistema/entrar'])
        } else if (resultado == 'not-found') {
          this.toastService.showErrorToast('Atenção', 'Código de inválido')
          this.router.navigate(['/'])
        } else {
          this.toastService.showErrorToast('Atenção', 'Não foi possível alterar, verique os valores e tente novamente')
        }
      })
    }
  }
}
