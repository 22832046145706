<div [class.collapse]="!collapsed" id="andamentos{{ id }}" *ngIf="andamentos?.length">
  <div class="mb-3">
    <fieldset class="border rounded-3 p-2 mt-0">
      <legend class="w-auto">Andamentos</legend>
      <div class="mt-3 mb-2">
        <div class="row">
          <div
            class="col-xs-12 col-sm-12 col-md-12 course-tabs-content"
            id="course-tabs-content-{{ id }}"
            style="margin-top: -1%; margin-bottom: -2%">
            <article id="UNESP-{{ id }}" class="course-tab-item" style="display: block">
              <div class="panel panel-default">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr *ngFor="let andamento of ordenacaoAndamentos(andamentos)">
                      <td>
                        <div class="row">
                          <div class="col-xs-12 col-sm-8 col-md-9">
                            <span
                              class="course-tab-item-title"
                              style="line-height: 1.8em; font-size: 10pt; font-weight: bold">
                              {{ andamento.titulo }}
                              <span *ngIf="andamento.complemento"
                                >- <span style="font-weight: lighter">{{ andamento.complemento }}</span></span
                              >
                              <span class="small"
                                ><br />
                                <span *ngIf="andamento.publicacao"
                                  >Publicado no DOE em {{ andamento.publicacao | date : 'dd/MM/yyyy' }}
                                </span>
                                <span *ngIf="andamento.pagina">página {{ andamento.pagina }} </span>
                                <span *ngIf="andamento.sessao">seção {{ andamento.sessao }}</span>
                              </span>
                            </span>
                          </div>
                          <div class="col-xs-12 col-sm-4 col-md-3 d-flex flex-row-reverse">
                            <a
                              *ngIf="andamento.anexo"
                              class="btn btn-outline-secondary btn-sm"
                              title="Acessar o Documento"
                              style="margin-left: 10px; max-height: 32px"
                              (click)="open(andamento.id, idConcurso ?? 0)"
                              >A</a
                            >
                            <span
                              class="badge label-branco pull-right"
                              style="font-size: 10pt; color: unset; margin-left: 10px"
                              attr.title="{{ andamento.data | date : 'dd/MM/yyyy HH:mm:ss' }}"
                              >{{ andamento.data | date : 'dd/MM/yyyy' }}</span
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </article>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</div>
