import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UsuarioService } from 'src/app/services/usuario.service'
import { ToastService } from '../../toast/services/toast.service'

@Component({
  selector: 'app-senha-recuperar',
  templateUrl: './senha-recuperar.component.html',
  styleUrls: ['./senha-recuperar.component.css'],
})
export class SenhaRecuperarComponent {
  form = new FormGroup({
    email: new FormControl('', Validators.required),
  })

  constructor(private usuarioService: UsuarioService, private toastService: ToastService) {}

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  enviar() {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast('Atenção', 'O formulário não foi preenchido corretamente')
      return
    }

    if (this.form.get('email')?.value) {
      let email = this.form.get('email')?.value ?? ''
      this.usuarioService.senhaRecuperar(email).subscribe(email => {
        if (email != '') {
          this.toastService.showSuccessToast('Atenção', `Siga as orientações enviadas para o e-mail ${email}`)
        } else {
          this.toastService.showErrorToast('Atenção', 'email não encontrado')
        }
        this.form.reset()
      })
    }
  }
}
