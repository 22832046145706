<div *ngIf="showDivComplemento">
  <div class="spinner-border" role="status" *ngIf="loading">
    <span class="visually-hidden">Loading...</span>
  </div>
  <div *ngIf="inscricaoComplementoSituacao?.hasComplementoInscricao">
    <div class="alert alert-warning mb-0" *ngIf="!loading && tipoFase1 === 'PENDENTE'">
      <p class="small">
        Documentação complementar da inscrição: {{ inscricaoComplementoSituacao?.totalObrigatorioPendente }} item(s)
        obrigatório(s) pendente(s).
      </p>
      <p class="small" *ngIf="opcionalPendente">
        Documentação complementar da inscrição:
        {{ inscricaoComplementoSituacao?.quantidadeMinimaOpcionalRequerido }} item(s) não obrigatório(s) pendentes.
      </p>
    </div>
    <div class="alert alert-danger mb-0" *ngIf="!loading && tipoFase1 === 'INCOMPLETA'">
      <p class="small">
        Documentação complementar da inscrição: {{ inscricaoComplementoSituacao?.totalObrigatorioPendente }} item(s)
        obrigatório(s) não enviado.
      </p>
      <p class="small" *ngIf="opcionalPendente">
        Documentação complementar da inscrição:
        {{ inscricaoComplementoSituacao?.quantidadeMinimaOpcionalRequerido }} item(s) não obrigatório(s) não enviado.
      </p>
    </div>
    <div class="alert alert-success mb-0" *ngIf="!loading && tipoFase1 === 'COMPLETA'">
      <p class="small">Documentação complementar da inscrição: envio completo.</p>
    </div>
  </div>

  <div *ngIf="inscricaoComplementoSituacao?.hasComplementoSegundaFase">
    <div class="alert alert-warning mb-0 mt-3" *ngIf="!loading && tipoFase2 === 'PENDENTE'">
      <p class="small">
        Documentação complementar da segunda fase:
        {{ inscricaoComplementoSituacao?.totalObrigatorioPendenteSegundaFase }} item(s) obrigatório(s) pendente(s).<br />
        <i>(inserção em até três dias úteis a partir da publicação do edital de convocação da segunda fase)</i>
      </p>
    </div>
    <div class="alert alert-danger mb-0 mt-3" *ngIf="!loading && tipoFase2 === 'INCOMPLETA'">
      <p class="small">
        Documentação complementar da segunda fase:
        {{ inscricaoComplementoSituacao?.totalObrigatorioPendenteSegundaFase }} item(s) obrigatório(s) não enviado.
      </p>
    </div>
    <div class="alert alert-success mb-0 mt-3" *ngIf="!loading && tipoFase2 === 'COMPLETA'">
      <p class="small">Documentação complementar da segunda fase: envio completo.</p>
    </div>
  </div>
</div>
