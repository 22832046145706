import { Component, OnInit } from '@angular/core'
import { delay } from 'rxjs'
import { LoaderService } from '../services/loader.service'

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
  loading: boolean = false

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.isLoading.pipe(delay(1)).subscribe(loading => {
      return (this.loading = loading)
    })
  }
}
