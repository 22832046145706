export abstract class PaginationBaseComponent {
  public current = 1
  public perPage = 10
  public totalPages = 0
  public totalElements = 0

  public onGoTo(page: number): void {
    this.current = page
    this.getData()
  }

  public onNext(page: number): void {
    this.current = page + 1
    this.getData()
  }

  public onPrevious(page: number): void {
    this.current = page - 1
    this.getData()
  }

  /**
   * @example
   *   let filter: any = { page: (this.current-1), size: this.perPage }
   *   this.{service}.{method}(filter).subscribe(data => {
   *     this.{any[]} = data['content']
   *     this.totalPages = data['totalPages']
   *     this.totalElements = data['totalElements']
   *   })
   */
  abstract getData(): void
}
