import { Component, Input } from '@angular/core'
import { TipoDocumento } from 'src/app/enums/tipo-documento'
import { InscricaoDocumento } from 'src/app/models/inscricao-documento'
import { DownloadService } from 'src/app/services/download.service'

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css'],
})
export class DocumentosComponent {
  @Input() documentos?: InscricaoDocumento[] = []
  @Input() id?: number = 0
  @Input() idConcurso?: number = 0
  @Input() collapsed: boolean = false

  constructor(private downloadService: DownloadService) {}

  tipoDocumentoStr(tipo: string): string {
    return (TipoDocumento as any)[tipo]
  }

  open(doc: InscricaoDocumento): void {
    this.downloadService.inscricaoDocumento(doc.id)
  }
}
