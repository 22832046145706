<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/candidato/inscricoes" title="Área do candidato" class="link">Área do candidato</a>
        </li>
        <li class="breadcrumb-item active">Documentação complementar da segunda fase</li>
      </ol>
    </nav>

    <app-formulario-situacao
      [idInscricao]="id"
      [events]="eventsSubject.asObservable()"
      #situacaoAnexos></app-formulario-situacao>

    <form>
      <app-candidato-fieldset-inscricao [inscricaoFicha]="inscricaoFicha"></app-candidato-fieldset-inscricao>

      <fieldset class="border rounded-3 p-2 no-print" *ngIf="temComplemento">
        <legend class="w-auto">Documentação complementar da segunda fase</legend>
        <div
          *ngFor="let inscricaoComplemento of inscricaoFicha?.inscricaoComplementoFase2"
          [ngSwitch]="inscricaoComplemento.tipoComplemento">
          <app-formulario-file
            *ngSwitchCase="'FILE'"
            [inscricaoComplemento]="inscricaoComplemento"
            [periodoPermitido]="inscricaoFicha?.periodoSegundaFase"
            (stateChanged)="onStateChange()">
          </app-formulario-file>
          <app-formulario-checkbox
            *ngSwitchCase="'CHECKBOX'"
            [inscricaoComplemento]="inscricaoComplemento"
            [periodoPermitido]="inscricaoFicha?.periodoSegundaFase"
            (stateChanged)="onStateChange()">
          </app-formulario-checkbox>
        </div>
      </fieldset>
    </form>
    <div class="no-print">
      <div class="row mt-3">
        <div class="col-12">
          <div class="alert alert-warning">
            Em caso de dúvida ou solicitação de alteração no formulário
            <a routerLink="/candidato/contato/{{ inscricaoFicha?.idConcurso }}">clique aqui</a>.
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: 10px">
        <div class="col-6">
          <a routerLink="/candidato/inscricoes" class="btn btn-secondary btn-sm"
            ><i class="bi bi-arrow-left-short" aria-hidden="true">&nbsp;</i> <span>Voltar</span></a
          >
        </div>
        <div class="col-6 d-flex flex-row-reverse" *ngIf="temComplemento && inscricaoFicha?.periodoSegundaFase">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#dialogConcluirModal">
            Confirmar Envio da Documentação
          </button>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="dialogConcluirModal"
          tabindex="-1"
          aria-labelledby="dialogConcluirModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="dialogConcluirModalLabel">Envio de arquivos</h1>
              </div>
              <div class="modal-body" [hidden]="!inscricaoCompleta">
                Falta a inserção de
                {{ this.situacaoAnexo?.inscricaoComplementoSituacao?.quantidadeTotaldeArquivosAusentesSegundaFase }}
                documentos. Deseja gravar os dados sem esses arquivos?
              </div>
              <div class="modal-body" [hidden]="inscricaoCompleta">
                Todos os documentos foram enviados. Deseja enviar o e-mail de notificação?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn btn-warning btn-sm" data-bs-dismiss="modal">
                  Não, retornar e finalizar a inserção dos documentos
                </button>
                <button type="button" (click)="gravar()" class="btn btn-primary" data-bs-dismiss="modal">Sim</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
