import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { PixPreview } from '../models/pix-preview'

@Injectable({
  providedIn: 'root',
})
export class PixModalService {
  pixModalEventsArquivo: Observable<PixPreview>
  private _pixModalEventsArquivo = new Subject<PixPreview>()

  constructor() {
    this.pixModalEventsArquivo = this._pixModalEventsArquivo.asObservable()
  }

  showPreview(url: string, title: string, text: string): void {
    this._pixModalEventsArquivo.next({
      url,
      title,
      text,
    })
  }
}
