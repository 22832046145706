import { Component, Input, OnInit } from '@angular/core'
import { Inscricao } from 'src/app/models/inscricao'
import { DownloadService } from 'src/app/services/download.service'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { ToastService } from '../../toast/services/toast.service'

@Component({
  selector: 'app-pagamento-deposito',
  templateUrl: './pagamento-deposito.component.html',
  styleUrls: ['./pagamento-deposito.component.css'],
})
export class PagamentoDepositoComponent implements OnInit {
  @Input() inscricao?: Inscricao
  loading: boolean = false
  file?: File | null
  inputClass: string = 'form-control'
  exibir: boolean = false
  aguardandoAprovacaoReducao: boolean = false

  constructor(
    private inscricaoService: InscricaoService,
    private downloadService: DownloadService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    if (this.inscricao?.periodoInscricao && this.inscricao?.deposito == 'SIM') {
      if (this.inscricao?.reducao && !this.inscricao.reducaoConcluida) {
        this.aguardandoAprovacaoReducao = true
      }
      if (this.inscricao?.periodoInscricao && !this.inscricao?.pago && !this.aguardandoAprovacaoReducao) {
        this.exibir = true
      }
    }
  }
}
