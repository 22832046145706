import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { BoletoPreview } from '../models/boleto-preview'

@Injectable({
  providedIn: 'root',
})
export class BoletoModalService {
  boletoModalEventsArquivo: Observable<BoletoPreview>
  private _boletoModalEventsArquivo = new Subject<BoletoPreview>()

  constructor() {
    this.boletoModalEventsArquivo = this._boletoModalEventsArquivo.asObservable()
  }

  showPreview(url: string, title: string): void {
    this._boletoModalEventsArquivo.next({
      url,
      title,
    })
  }
}
