<div class="spinner-border" role="status" *ngIf="loading">
  <span class="visually-hidden">Loading...</span>
</div>

<div *ngIf="!periodoPermitido && !loading">
  <div class="row" *ngIf="!inscricaoComplemento.valor">
    <div class="col-sm-12" style="margin-top: 10px">
      <p class="small fw-bold" style="margin: 0 0 0 5px">
        {{ inscricaoComplemento.grupo }}
        <span *ngIf="inscricaoComplemento.grupo && inscricaoComplemento.explicacao"> - </span>
        {{ inscricaoComplemento.explicacao }}
      </p>
      <div id="group_{{ inscricaoComplemento.id }}" class="input-group">
        <span [ngClass]="inscricaoComplemento.requerido ? 'input-group-text text-danger' : 'input-group-text'">
          {{ inscricaoComplemento.requerido ? 'Obrigatório' : 'Opcional' }}
        </span>
        <input [ngClass]="inputClass" type="text" value="Não informado" readonly="readonly" />
      </div>
    </div>
  </div>

  <div class="row" *ngIf="inscricaoComplemento.valor">
    <div class="col-sm-12" style="margin-top: 10px">
      <p class="small fw-bold" style="margin: 0 0 0 5px">
        {{ inscricaoComplemento.grupo }}
        <span *ngIf="inscricaoComplemento.grupo && inscricaoComplemento.explicacao"> - </span>
        {{ inscricaoComplemento.explicacao }}
      </p>
    </div>
    <div class="col-sm-12">
      <div id="group_{{ inscricaoComplemento.id }}" class="input-group">
        <span [ngClass]="inscricaoComplemento.requerido ? 'input-group-text text-danger' : 'input-group-text'">
          {{ inscricaoComplemento.requerido ? 'Obrigatório' : 'Opcional' }}
        </span>
        <a (click)="open()" class="btn btn-default btn-block form-control" style="text-align: left !important"
          >Clique aqui para visualizar o arquivo enviado</a
        >
      </div>
    </div>
  </div>
</div>

<div *ngIf="periodoPermitido && !loading">
  <div class="row" *ngIf="!inscricaoComplemento.valor">
    <div class="col-sm-12" style="margin-top: 10px">
      <p class="small fw-bold" style="margin: 0 0 0 5px">
        {{ inscricaoComplemento.grupo }}
        <span *ngIf="inscricaoComplemento.grupo && inscricaoComplemento.explicacao"> - </span>
        {{ inscricaoComplemento.explicacao }}
      </p>
      <div id="group_{{ inscricaoComplemento.id }}" class="input-group">
        <span [ngClass]="inscricaoComplemento.requerido ? 'input-group-text text-danger' : 'input-group-text'">
          {{ inscricaoComplemento.requerido ? 'Obrigatório' : 'Opcional' }}
        </span>
        <input
          id="{{ inscricaoComplemento.id }}"
          [ngClass]="inputClass"
          type="file"
          (change)="onFileSelected($event)"
          #fileUpload />
      </div>
    </div>
  </div>

  <div class="row" *ngIf="inscricaoComplemento.valor">
    <div class="col-sm-12" style="margin-top: 10px">
      <p class="small fw-bold" style="margin: 0 0 0 5px">
        {{ inscricaoComplemento.grupo }}
        <span *ngIf="inscricaoComplemento.grupo && inscricaoComplemento.explicacao"> - </span>
        {{ inscricaoComplemento.explicacao }}
      </p>
    </div>
    <div class="col-sm-6">
      <div class="input-group">
        <span [ngClass]="inscricaoComplemento.requerido ? 'input-group-text text-danger' : 'input-group-text'">
          {{ inscricaoComplemento.requerido ? 'Obrigatório' : 'Opcional' }}
        </span>
        <a (click)="open()" class="btn btn-default btn-block form-control" style="text-align: left !important"
          >Clique aqui para visualizar o arquivo enviado</a
        >
      </div>
    </div>
    <div class="col-sm-6">
      <div id="group_{{ inscricaoComplemento.id }}" class="input-group">
        <span class="input-group-text">
          <i class="bi bi-pencil-square">&nbsp;</i>
          Alterar arquivo enviado
        </span>
        <input
          id="{{ inscricaoComplemento.id }}"
          [ngClass]="inputClass"
          type="file"
          (change)="onFileSelected($event)"
          #fileUpload />
      </div>
    </div>
  </div>
</div>

<hr style="border: 0; border-top: dotted 1px #000" />
