import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BoletoModalComponent } from './components/boleto-modal.component'

@NgModule({
  declarations: [BoletoModalComponent],
  imports: [CommonModule],
  exports: [BoletoModalComponent],
})
export class BoletoModalModule {}
