<section>
  <h2 style="display: none">Alterar senha</h2>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/candidato/inscricoes" title="Área do candidato">Área do candidato</a>
        </li>
        <li class="breadcrumb-item active">Alterar Senha</li>
      </ol>
    </nav>
    <form [formGroup]="form">
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">Alterar a Senha</legend>
        <div class="mb-3 mt-3">
          <div class="input-group">
            <label class="input-group-text">Senha atual</label>
            <input class="form-control" formControlName="senhaAtual" type="password" maxlength="64" />
          </div>
        </div>
        <div class="mb-3">
          <div class="input-group">
            <label class="input-group-text">Nova Senha</label>
            <input class="form-control" formControlName="novaSenha" type="password" maxlength="64" />
          </div>
          <div id="ttSenha" class="form-text">
            * Sua senha deve conter, pelo menos: 1 Letra maiúscula, 1 Letra minúscula, 1 Número e 8 Caracteres.
          </div>
        </div>
        <div>
          <div class="input-group">
            <label class="input-group-text">Confirmação</label>
            <input class="form-control" formControlName="novaSenhaConfirmacao" type="password" maxlength="64" />
          </div>
        </div>
      </fieldset>
      <div class="row mt-3">
        <div class="col-12 d-flex flex-row-reverse">
          <a (click)="alterar()" class="btn btn-primary btn-sm"
            ><i class="bi bi-check-circle" aria-hidden="true">&nbsp;</i> <span>Enviar</span></a
          >
        </div>
      </div>
    </form>
  </div>
</section>
