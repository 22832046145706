<div class="spinner-border" role="status" *ngIf="loading">
  <span class="visually-hidden">Loading...</span>
</div>

<div *ngIf="!loading && exibir && !inscricao?.depositoAnexo">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto">Pagamento por depósito</legend>

    <div *ngIf="!inscricao?.depositoAnexo">
      <p class="small mt-2">
        Prezado candidato, para efetuar o pagamento da sua inscrição via depósito ou transferência utilize os dados
        abaixo:
      </p>
      <ul class="mb-0">
        <li>
          Banco: <b>{{ inscricao?.depositoBanco }}</b>
        </li>
        <li>
          Agência: <b>{{ inscricao?.depositoAgencia }}</b>
        </li>
        <li>
          Conta corrente: <b>{{ inscricao?.depositoConta }}</b>
        </li>
        <li>
          Valor: <b>R$ {{ inscricao?.valor }}</b>
        </li>
      </ul>
    </div>
  </fieldset>
</div>
<div *ngIf="aguardandoAprovacaoReducao">
  <fieldset class="border rounded-3 p-2">
    <legend class="w-auto mb-1">Pagamento por depósito</legend>
    <ul class="mb-0">
      <li class="text-danger">Aguardando a decisão do RH sobre seu pedido de redução de taxa</li>
    </ul>
  </fieldset>
</div>
