import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PixModalComponent } from './components/pix-modal.component'

@NgModule({
  declarations: [PixModalComponent],
  imports: [CommonModule],
  exports: [PixModalComponent],
})
export class PixModalModule {}
