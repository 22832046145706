import { Component } from '@angular/core'
import { UsuarioService } from 'src/app/services/usuario.service'
import packageJson from '../../../../../package.json'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  version: string = packageJson.version

  constructor(public usuarioService: UsuarioService) {}
}
