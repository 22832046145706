import { Component, Input } from '@angular/core'
import { CriterioProva } from 'src/app/models/nota'

@Component({
  selector: '[app-inscricao-nota-criterio]',
  templateUrl: './inscricao-nota-criterio.component.html',
  styleUrls: ['./inscricao-nota-criterio.component.css'],
})
export class InscricaoNotaCriterioComponent {
  @Input() criterios!: CriterioProva[]
}
