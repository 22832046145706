import { Component, Input, OnInit } from '@angular/core'
import { PixModalService } from '../services/pix-modal.service'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { PixPreview } from '../models/pix-preview'

@Component({
  selector: 'app-pix-modal',
  templateUrl: './pix-modal.component.html',
  styleUrls: ['./pix-modal.component.css'],
})
export class PixModalComponent implements OnInit {
  pixPreview?: PixPreview
  exibir: boolean = false
  @Input() trustedUrl!: SafeUrl
  windowHeight: number = 400
  exibeMensagem: boolean = false
  codigoCopiaECola: string = ''

  constructor(private sanitizer: DomSanitizer, private pixModalService: PixModalService) {}

  ngOnInit(): void {
    this.windowHeight = window.innerHeight - 200
    this.subscribeToModal()
  }

  subscribeToModal() {
    this.pixModalService.pixModalEventsArquivo.subscribe(pixPreview => {
      this.pixPreview = pixPreview
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pixPreview.url)
      this.toggle()
      this.codigoCopiaECola = pixPreview.text
    })
  }

  toggle(): void {
    this.exibir = !this.exibir
  }

  copiaECola() {
    navigator.clipboard.writeText(this.codigoCopiaECola)
    this.exibeMensagem = true
  }
}
