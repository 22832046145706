<section>
  <h2 style="display: none">Recursos</h2>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/candidato/inscricoes" title="Área do candidato">Área do candidato</a>
        </li>
        <li class="breadcrumb-item active">Recursos</li>
      </ol>
    </nav>
    <div class="bg-light border rounded-3 mb-3" *ngFor="let recurso of recursos">
      <a routerLink="/candidato/recurso/{{ recurso.id }}" style="color: inherit; text-decoration: none">
        <div class="p-3">
          <p style="font-size: 1rem">{{ recurso.campus }}</p>
          <h3 class="text-primary" style="font-size: 1rem">{{ recurso.tituloConcurso }}</h3>
          <h4 style="font-size: 1rem; font-weight: normal">{{ recurso.data | date : 'dd/MM/yyyy HH:mm:ss' }}</h4>
          <h5
            style="font-size: 1rem; font-weight: bold"
            class="{{ recurso.parecer === 'Em Análise' ? 'text-danger' : 'text-dark' }}">
            {{ recurso.parecer }}
          </h5>
        </div>
      </a>
    </div>
    <div class="alert alert-warning" *ngIf="!loading && !recursos.length">
      Prezado candidato, você não possui nenhum recurso.
    </div>
  </div>
</section>
