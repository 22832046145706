<section>
  <h2 style="display: none">Recurso</h2>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/candidato/inscricoes" title="Área do candidato">Área do candidato</a>
        </li>
        <li class="breadcrumb-item"><a routerLink="/candidato/recursos" title="Recursos">Recursos</a></li>
        <li class="breadcrumb-item active">Recurso</li>
      </ol>
    </nav>
    <div class="print-header"></div>
    <div class="bg-light border rounded-3 mb-3" *ngIf="!loading">
      <div class="p-3">
        <p style="font-size: 1rem">{{ recurso?.campus }}</p>
        <h3 class="text-primary" style="font-size: 1rem">{{ recurso?.tituloConcurso }}</h3>
        <h4 style="font-size: 1rem; font-weight: normal">{{ recurso?.data | date : 'dd/MM/yyyy HH:mm:ss' }}</h4>
        <h5 style="font-size: 1rem; font-weight: bold">{{ recurso?.tipoRecurso }}</h5>
        <hr />
        <h5 style="font-size: 1rem; font-weight: normal; white-space: pre-line">{{ recurso?.texto }}</h5>
        <hr />
        <h5
          style="font-size: 1rem; font-weight: bold"
          class="{{ recurso?.parecer === 'Em Análise' ? 'text-danger' : 'text-dark' }}">
          {{ recurso?.parecer }}
          <span *ngIf="recurso?.parecerData !== null"> {{ recurso?.parecerData | date : 'dd/MM/yyyy HH:mm:ss' }}</span>
        </h5>
        <div class="no-print">
          <a class="btn btn-warning btn-sm" (click)="print()"><i class="bi bi-printer">&nbsp;</i>Imprimir</a>
          <a class="btn btn-primary btn-sm ms-3" (click)="open()" *ngIf="recurso?.anexo"
            ><i class="bi bi-eye">&nbsp;</i>Visualizar anexo</a
          >
          <a class="btn btn-primary btn-sm ms-3" (click)="openParecer()" *ngIf="recurso?.parecerAnexo !== null"
            ><i class="bi bi-eye">&nbsp;</i>Visualizar Parecer</a
          >
        </div>
      </div>
    </div>
    <div class="row no-print" style="margin-top: 20px">
      <div class="col-12">
        <a routerLink="/candidato/recursos" class="btn btn-secondary btn-sm"
          ><i class="bi bi-arrow-left-short" aria-hidden="true">&nbsp;</i> <span>Voltar</span></a
        >
      </div>
    </div>
  </div>
</section>
