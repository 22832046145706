import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { identity, Observable } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { Endereco } from '../models/endereco'

@Injectable({
  providedIn: 'root',
})
export class CepService {
  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {}

  consultaCEP(cep: String): Observable<Endereco> {
    return this.http
      .get<Endereco>(`https://viacep.com.br/ws/${cep}/json/`)
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
