import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { finalize, Observable } from 'rxjs'
import { LoaderService } from '../modules/loader/services/loader.service'

@Injectable({
  providedIn: 'root',
})
export class LoaderHttpInterceptor implements HttpInterceptor {
  serviceCount: number = 0

  constructor(private loaderService: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true)
    this.serviceCount++
    return next.handle(req).pipe(
      finalize(() => {
        this.serviceCount--
        if (this.serviceCount === 0) {
          this.loaderService.isLoading.next(false)
        }
      })
    )
  }
}
