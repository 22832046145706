import { Component, Input, OnInit } from '@angular/core'
import { SafeUrl, DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-inscricoes-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.css'],
})
export class ManualComponent implements OnInit {
  @Input() trustedUrl!: SafeUrl
  iframeHeight: number = 200
  perfil: string = ''

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://wikicrh.unesp.br/mediawiki/images/7/71/Manual_do_Candidato_-_Sistema_de_Inscri%C3%A7%C3%B5es.pdf'
    )
    this.iframeHeight = window.innerHeight
  }
}
