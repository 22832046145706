import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { InscricaoComplemento } from 'src/app/models/inscricao-complemento'
import { InscricaoComplementoService } from 'src/app/services/inscricao-complemento.service'
import { ToastService } from '../../toast/services/toast.service'

@Component({
  selector: 'app-formulario-checkbox',
  templateUrl: './formulario-checkbox.component.html',
  styleUrls: ['./formulario-checkbox.component.css'],
})
export class FormularioCheckboxComponent implements OnInit {
  @Output() stateChanged = new EventEmitter()
  @Input() inscricaoComplemento?: InscricaoComplemento
  @Input() periodoPermitido?: boolean
  loading: boolean = false
  spanClass: string = 'input-group-text'
  input: string = ''

  constructor(private inscricaoComplementoService: InscricaoComplementoService, private toastService: ToastService) {}

  ngOnInit(): void {
    if (this.inscricaoComplemento && this.inscricaoComplemento.tipoComplemento.localeCompare('CHECKBOX') === 0) {
      this.input = this.inscricaoComplemento.valor
    }

    if (this.inscricaoComplemento?.requerido) {
      this.spanClass = 'input-group-text text-danger'
      this.input = this.inscricaoComplemento.valor
    }
  }

  enviar(): void {
    const formData = new FormData()
    formData.append('checked', this.input)
    if (this.inscricaoComplemento) {
      this.loading = true
      this.inscricaoComplementoService
        .checkbox(this.inscricaoComplemento?.idInscricao, this.inscricaoComplemento?.id, formData)
        .subscribe(inscricaoComplemento => {
          if (inscricaoComplemento) {
            this.inscricaoComplemento = inscricaoComplemento
            this.toastService.showSuccessToast('Parabéns', 'Registrado com sucesso')
          } else {
            this.toastService.showErrorToast('Atenção', 'Não foi possível registrar')
          }
          this.loading = false
          this.stateChanged.emit()
        })
    }
  }
}
