import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { identity, Observable } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { environment } from '../../environments/environment'
import { Recurso } from '../models/recurso'
import { RecursosDisponiveis } from '../models/recursos-disponiveis'
import { RecursoDetalhes } from '../models/recurso-detalhes'

@Injectable({
  providedIn: 'root',
})
export class RecursoService {
  private baseUrl: string

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {
    this.baseUrl = environment.baseUrlApi
  }

  recursosDisponiveis(idInscricao: number): Observable<RecursosDisponiveis> {
    return this.http
      .get<RecursosDisponiveis>(`${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/recurso`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  novoRecurso(idInscricao: number, jsonData: any): Observable<Recurso> {
    return this.http
      .post<Recurso>(`${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/recurso`, jsonData)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  anexar(id: number, formData: FormData): Observable<Recurso> {
    return this.http
      .post<Recurso>(`${this.baseUrl}/privado/candidato/recurso/${id}/anexo`, formData)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listar(): Observable<Recurso[]> {
    return this.http
      .get<Recurso[]>(`${this.baseUrl}/privado/candidato/recurso`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  get(id: number): Observable<RecursoDetalhes> {
    return this.http
      .get<RecursoDetalhes>(`${this.baseUrl}/privado/candidato/recurso/${id}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
