import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { identity, Observable } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { Inscricao } from '../models/inscricao'
import { InscricaoFicha } from '../models/inscricao-ficha'
import { environment } from '../../environments/environment'
import { Boleto } from '../models/boleto'
import { InscricaoResponse } from '../models/inscricao-response'
import { ContaBancaria } from '../models/conta-bancaria'
import { Pix } from '../models/pix'

@Injectable({
  providedIn: 'root',
})
export class InscricaoService {
  private baseUrl: string

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {
    this.baseUrl = environment.baseUrlApi
  }

  listar(filter: any): Observable<any> {
    const params = filter
    return this.http
      .get<any>(`${this.baseUrl}/privado/candidato/inscricao`, { params })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  get(id: number): Observable<InscricaoFicha> {
    return this.http
      .get<InscricaoFicha>(`${this.baseUrl}/privado/candidato/inscricao/${id}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  alterar(id: number, form: InscricaoFicha): Observable<InscricaoFicha> {
    return this.http
      .put<InscricaoFicha>(`${this.baseUrl}/privado/candidato/inscricao/${id}`, form)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  gravar(form: InscricaoFicha): Observable<InscricaoResponse> {
    return this.http
      .post<InscricaoResponse>(`${this.baseUrl}/publico/inscricao`, form)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  reducaoTaxa(idInscricao: number, formData: FormData): Observable<InscricaoResponse> {
    return this.http
      .post<InscricaoResponse>(`${this.baseUrl}/publico/inscricao/${idInscricao}/reducao-taxa`, formData)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  deficienciaLaudoMedico(idInscricao: number, formData: FormData): Observable<InscricaoResponse> {
    return this.http
      .post<InscricaoResponse>(`${this.baseUrl}/publico/inscricao/${idInscricao}/deficiencia-laudo-medico`, formData)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  comprovantePagamento(idInscricao: number, formData: FormData): Observable<Inscricao> {
    return this.http
      .post<Inscricao>(`${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/comprovante-pagamento`, formData)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  boleto(idInscricao: number, tipo: string): Observable<Boleto> {
    return this.http
      .get<Boleto>(`${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/${tipo}/pagamento`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  pix(idInscricao: number, tipo: string): Observable<Pix> {
    return this.http
      .get<Pix>(`${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/${tipo}/pagamento`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  temas(idInscricao: number): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/temas`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  solicitarDevolucao(idInscricao: number, form: ContaBancaria): Observable<Inscricao> {
    return this.http
      .post<Inscricao>(`${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/devolucao-pagamento`, form)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  submeteInscricao(idInscricao: number, idConcurso: number) {
    return this.http
      .post<Inscricao>(
        `${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/submete-inscricao/${idConcurso}/`,
        null
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
