import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UsuarioService } from 'src/app/services/usuario.service'
import { ToastService } from '../../toast/services/toast.service'

@Component({
  selector: 'app-senha-alterar',
  templateUrl: './senha-alterar.component.html',
  styleUrls: ['./senha-alterar.component.css'],
})
export class SenhaAlterarComponent {
  senhaPattern: string = '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d).{8,}$'

  form = new FormGroup({
    senhaAtual: new FormControl('', Validators.required),
    novaSenha: new FormControl('', [Validators.required, Validators.pattern(this.senhaPattern)]),
    novaSenhaConfirmacao: new FormControl('', [Validators.required, Validators.pattern(this.senhaPattern)]),
  })

  constructor(private usuarioService: UsuarioService, private toastService: ToastService) {}

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  alterar() {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast('Atenção', 'O formulário não foi preenchido corretamente')
      return
    }

    let novaSenha = this.form.get('novaSenha')?.value ?? ''
    let novaSenhaConfirmacao = this.form.get('novaSenhaConfirmacao')?.value ?? ''
    if (novaSenha !== novaSenhaConfirmacao) {
      this.toastService.showErrorToast('Atenção', 'A Nova Senha e a Confirmação não são iguais')
    } else {
      var jsonData = {
        senhaAtual: this.form.get('senhaAtual')?.value,
        novaSenha: this.form.get('novaSenha')?.value,
      }
      this.usuarioService.senhaAlterar(jsonData).subscribe(resultado => {
        if (resultado == 'updated') {
          this.toastService.showSuccessToast('Atenção', 'Sua senha foi alterado com sucesso')
          this.form.reset()
        } else {
          this.toastService.showErrorToast('Atenção', 'Não foi possível alterar, verique os valores e tente novamente')
        }
      })
    }
  }
}
