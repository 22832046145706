<nav class="justify-content-center" *ngIf="totalPages > 1">
  <ul class="pagination justify-content-center" *ngIf="totalElements > 0">
    <li class="page-item" *ngIf="current === 1"><a class="page-link disabled">‹</a></li>
    <li class="page-item" *ngIf="current !== 1"><a class="page-link" (click)="onPrevious()">‹</a></li>
    <li *ngFor="let page of pages; index as i" class="page-item">
      <a
        *ngIf="page !== -1; else more"
        class="page-link"
        [class.active]="page === current"
        tabindex="0"
        (click)="onGoTo(page)"
        (keyup.enter)="onGoTo(page)">
        {{ page }}
      </a>
      <ng-template #more>
        <a class="page-link disabled">...</a>
      </ng-template>
    </li>
    <li class="page-item" *ngIf="current === totalPages"><a class="page-link disabled">›</a></li>
    <li class="page-item" *ngIf="current !== totalPages"><a class="page-link" (click)="onNext()">›</a></li>
  </ul>
  <div class="col text-center">Total de registros: {{ totalElements }}</div>
</nav>
