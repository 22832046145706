<section>
  <div class="container">
    <div class="alert alert-success">
      <h4>Recuperação de senha</h4>
      Prezado candidato, para recuperar sua senha informe seu Email e siga as orientações que receberá em seu Email.
    </div>
    <div class="row">
      <div class="col-lg-12">
        <form [formGroup]="form">
          <div class="input-group mb-3">
            <span class="input-group-text">Email</span>
            <input
              type="email"
              class="form-control"
              aria-label="email"
              placeholder="Informe seu Email"
              autofocus="autofocus"
              autocomplete="off"
              formControlName="email"
              [email]="true" />
          </div>
          <div class="form-group">
            <a class="btn btn-primary btn-md" (click)="enviar()" style="width: 100%"
              ><i class="bi bi-envelope" aria-hidden="true">&nbsp;</i> Enviar</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
