import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { fromEvent, take } from 'rxjs'
import { EventTypes } from '../../models/event-types'

declare var $: any

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css'],
})
export class ToastComponent implements OnInit {
  @Output() disposeEvent = new EventEmitter()
  @Input() type!: EventTypes
  @Input() title!: string
  @Input() message!: string

  ngOnInit() {
    this.show()
  }

  show() {
    $('.toast')
      .last()
      .toast({ delay: this.type === EventTypes.Error ? 5000 : 3000 })
    $('.toast').last().toast('show')
    fromEvent($('.toast').last(), 'hidden.bs.toast')
      .pipe(take(1))
      .subscribe(() => this.hide())
  }

  hide() {
    this.disposeEvent.emit()
  }
}
