import { Component, OnInit, Input } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { ContaBancaria } from 'src/app/models/conta-bancaria'
import { Inscricao } from 'src/app/models/inscricao'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { ToastService } from '../../toast/services/toast.service'

@Component({
  selector: 'app-pagamento-devolucao',
  templateUrl: './pagamento-devolucao.component.html',
  styleUrls: ['./pagamento-devolucao.component.css'],
})
export class PagamentoDevolucaoComponent implements OnInit {
  @Input() inscricao?: Inscricao
  loading: boolean = false
  exibir: boolean = false

  form = new FormGroup({
    banco: new FormControl('', Validators.required),
    agencia: new FormControl('', Validators.required),
    conta: new FormControl('', Validators.required),
    variacao: new FormControl('', Validators.required),
  })

  constructor(private inscricaoService: InscricaoService, private toastService: ToastService) {}

  ngOnInit(): void {
    this.form.reset()
    if (this.inscricao?.devolucaoHabilitada && this.inscricao?.pago) {
      this.exibir = true
    }
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  enviar(): void {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast('Atenção', 'O formulário não foi preenchido corretamente')
      return
    }

    let formulario = <ContaBancaria>this.form.value

    this.loading = true
    this.form.reset()
    if (this.inscricao) {
      this.inscricaoService.solicitarDevolucao(this.inscricao.id, formulario).subscribe(inscricao => {
        if (inscricao) {
          this.inscricao = inscricao
          this.toastService.showSuccessToast('Parabéns', 'Sua solicitação foi registrada com sucesso')
        } else {
          this.toastService.showErrorToast('Atenção', 'Não foi possível registrar sua solicitação')
        }
        this.loading = false
      })
    }
  }
}
