<section>
  <div class="container">
    <div class="col-xs-12">
      <div class="centralizado">
        <form [formGroup]="form">
          <div class="cardLogin">
            <div class="headline text-center" style="margin-top: 8%"><b>Área do candidato</b></div>
            <hr class="mt-3" />
            <div class="row mb-3">
              <label class="fw-bold">Email:</label>
              <div class="input-group">
                <span class="input-group-text mobile-none"><i class="bi bi-person">&nbsp;</i></span>
                <input
                  type="email"
                  class="form-control"
                  aria-label="username"
                  placeholder="Informe seu Email"
                  autofocus="autofocus"
                  autocomplete="off"
                  formControlName="username"
                  [email]="true" />
              </div>
            </div>
            <div class="row mb-3">
              <label class="fw-bold">Senha:</label>
              <div class="input-group">
                <span class="input-group-text mobile-none"><i class="bi bi-lock">&nbsp;</i></span>
                <input
                  type="password"
                  class="form-control"
                  placeholder="Informe sua Senha"
                  aria-label="password"
                  autocomplete="off"
                  formControlName="password" />
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <a (click)="login()" class="btn btn-lg btn-primary" style="width: 100%" aria-hidden="true">Entrar</a>
              </div>
              <div class="col-12 text-center mt-3">
                <a routerLink="/sistema/senha-recuperar" class="btn btn-default text-primary" style="border: 0px"
                  >Esqueceu sua senha?</a
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
