import { Component, Input } from '@angular/core'
import { InscricaoFicha } from 'src/app/models/inscricao-ficha'

@Component({
  selector: 'app-candidato-fieldset-inscricao',
  templateUrl: './candidato-fieldset-inscricao.component.html',
  styleUrls: ['./candidato-fieldset-inscricao.component.css'],
})
export class CandidatoFieldsetInscricaoComponent {
  @Input() inscricaoFicha?: InscricaoFicha

  constructor() {}
}
