<section>
  <h2 style="display: none">Novo Recurso</h2>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/candidato/inscricoes" title="Área do candidato">Área do candidato</a>
        </li>
        <li class="breadcrumb-item active">Novo Recurso</li>
      </ol>
    </nav>
    <form [formGroup]="form">
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">Novo Recurso</legend>
        <div class="row mt-2">
          <div class="col-12">
            <div class="input-group">
              <label class="input-group-text">Câmpus</label>
              <input type="text" class="form-control" value="{{ recursosDisponiveis?.campus }}" readonly="readonly" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="input-group">
              <label class="input-group-text">Concurso</label>
              <input
                type="text"
                class="form-control"
                value="{{ recursosDisponiveis?.tituloConcurso }}"
                readonly="readonly" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="input-group">
              <span class="input-group-text">Recurso</span>
              <select class="form-control" formControlName="tipoRecurso">
                <option value=""></option>
                <option *ngFor="let value of recursosDisponiveis?.recursos">{{ value }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="input-group">
              <label class="input-group-text">Texto</label>
              <textarea class="form-control" formControlName="texto" rows="5"></textarea>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="input-group">
              <span class="input-group-text">Anexo</span>
              <input
                [ngClass]="inputClass"
                type="file"
                (change)="onFileSelected($event)"
                #fileUpload
                formControlName="anexo" />
            </div>
          </div>
        </div>
      </fieldset>
      <div class="row mt-3" *ngIf="conferencia === false">
        <div class="col-6">
          <a (click)="voltar()" class="btn btn-secondary btn-sm"
            ><i class="bi bi-arrow-left-short" aria-hidden="true">&nbsp;</i> <span>Voltar</span></a
          >
        </div>
        <div class="col-6 d-flex flex-row-reverse">
          <a (click)="conferir()" class="btn btn-primary btn-sm"
            ><i class="bi bi-check-circle" aria-hidden="true">&nbsp;</i> <span>Enviar</span></a
          >
        </div>
      </div>
      <div class="row mt-3" *ngIf="conferencia === true">
        <div class="col-6">
          <a (click)="cancelar()" class="btn btn-secondary btn-sm"
            ><i class="bi bi-pencil" aria-hidden="true">&nbsp;</i> <span>Alterar</span></a
          >
        </div>
        <div class="col-6 d-flex flex-row-reverse">
          <a (click)="enviar()" class="btn btn-success btn-sm"
            ><i class="bi bi-check-circle" aria-hidden="true">&nbsp;</i> <span>Confirmar</span></a
          >
        </div>
      </div>
    </form>
  </div>
</section>
