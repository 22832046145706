import { Component, Input } from '@angular/core'
import { Andamento } from 'src/app/models/andamento'
import { DownloadService } from 'src/app/services/download.service'

@Component({
  selector: 'app-andamento',
  templateUrl: './andamento.component.html',
  styleUrls: ['./andamento.component.css'],
})
export class AndamentoComponent {
  @Input() andamentos?: Andamento[] = []
  @Input() id?: number = 0
  @Input() idConcurso?: number = 0
  @Input() collapsed: boolean = false
  tamanho: number = 0

  constructor(private downloadService: DownloadService) {}

  open(id: number, idConcurso: number): void {
    if (id == null) {
      this.downloadService.edital(idConcurso)
    } else {
      this.downloadService.andamento(id)
    }
  }

  ordenacaoAndamentos(andamentos: Andamento[] | undefined): Andamento[] | undefined {
    if (andamentos) {
      this.tamanho = andamentos.length
      for (let i = 0; i < this.tamanho; i++) {
        for (let j = 0; j < this.tamanho - i - 1; j++) {
          if (Date.parse(andamentos[j].data) > Date.parse(andamentos[j + 1].data)) {
            let temp = andamentos[j]
            andamentos[j] = andamentos[j + 1]
            andamentos[j + 1] = temp
          }
        }
      }
    }
    return andamentos
  }
}
