<div class="spinner-border" role="status" *ngIf="loading">
  <span class="visually-hidden">Loading...</span>
</div>

<div class="row" *ngIf="periodoPermitido && !loading">
  <div class="col-sm-12" style="margin-top: 10px">
    <div id="group_{{ inscricaoComplemento?.id }}" class="input-group">
      <span [ngClass]="spanClass">{{ inscricaoComplemento?.requerido ? 'Obrigatório' : 'Opcional' }}</span>
      <div class="input-group-text">
        <input
          type="checkbox"
          class="form-control form-check-input"
          id="{{ inscricaoComplemento?.id }}"
          [(ngModel)]="input"
          (change)="enviar()" />
      </div>
      <p class="form-control" style="height: 100%">
        {{ inscricaoComplemento?.grupo }}<br />{{ inscricaoComplemento?.explicacao }}
      </p>
    </div>
  </div>
</div>

<div class="row" *ngIf="!periodoPermitido && !loading">
  <div class="col-sm-12" style="margin-top: 10px">
    <div id="group_{{ inscricaoComplemento?.id }}" class="input-group">
      <span [ngClass]="spanClass">{{ inscricaoComplemento?.requerido ? 'Obrigatório' : 'Opcional' }}</span>
      <div class="input-group-text">
        {{ input === 'on' ? 'Sim' : 'Não informado' }}
      </div>
      <p class="form-control" style="height: 100%">
        {{ inscricaoComplemento?.grupo }}<br />{{ inscricaoComplemento?.explicacao }}
      </p>
    </div>
  </div>
</div>

<hr style="border: 0; border-top: dotted 1px #000" />
