<nav class="navbar navbar-expand-lg navbar-dark bg-primary mb-3">
  <h6 style="display: none">MENU</h6>
  <div class="container">
    <a class="navbar-brand" routerLink="/">
      <div class="inline-grid">
        <img src="assets/image/unesplogowhite.png" width="92" height="30" alt="unesp" title="unesp" />
        <small class="version">v {{ version }}</small>
      </div>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target=".navbar-collapse"
      aria-controls="navbar"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon">&nbsp;</span>
    </button>
    <div class="collapse navbar-collapse" id="navbar">
      <ul class="navbar-nav mr-auto mb-2 mb-md-0">
        <li class="nav-item">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
            routerLink="/concurso/inscricao-aberta"
            title="Concursos"
            >Concursos</a
          >
        </li>
      </ul>
      <ul class="navbar-nav ms-auto mb-2 mb-md-0">
        <li class="nav-item" *ngIf="!usuarioService.isLoggedIn()">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
            routerLink="/sistema/entrar"
            title="Área do candidato"
            >Área do candidato</a
          >
        </li>
        <li class="nav-item dropdown" *ngIf="usuarioService.isLoggedIn()">
          <a
            class="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            title="Área do candidato"
            >Área do candidato</a
          >
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li>
              <a
                class="dropdown-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                routerLink="/candidato/inscricoes"
                title="Minhas Inscrições"
                >Inscrições</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                routerLink="/candidato/recursos"
                title="Meus Recursos"
                >Recursos</a
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a
                class="dropdown-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                routerLink="/candidato/senha-alterar"
                title="Alterar senha"
                >Alterar senha</a
              >
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
            routerLink="/sistema/manual"
            title="Manual do sistema"
            >Manual do sistema</a
          >
        </li>
        <li class="nav-item" *ngIf="usuarioService.isLoggedIn()">
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
            (click)="usuarioService.logout()"
            title="Sair"
            >Sair</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
