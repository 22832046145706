import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { ActivatedRoute } from '@angular/router'
import { Sexo } from 'src/app/enums/sexo'
import { EstadoCivil } from 'src/app/enums/estado-civil'
import { Estado } from 'src/app/enums/estado'
import { OrgaoExpedicaoRg } from 'src/app/enums/orgao-expedicao-rg'
import { InscricaoFicha } from 'src/app/models/inscricao-ficha'
import { GrauEscolaridade } from 'src/app/enums/grau-escolaridade'
import { Estrangeiro } from 'src/app/enums/estrangeiro'
import { Deficiencia } from 'src/app/enums/deficiencia'
import { CidadeService } from 'src/app/services/cidade.service'
import { DataService } from 'src/app/services/data.service'
import { ValidacaoService } from 'src/app/services/validacao.service'
import { ToastService } from '../../toast/services/toast.service'
import { Subject } from 'rxjs'
import { DownloadService } from 'src/app/services/download.service'
import { Autodeclaracao } from 'src/app/enums/autodeclaracao'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'

@Component({
  selector: 'app-candidato-inscricao-ficha',
  templateUrl: './candidato-inscricao-ficha.component.html',
  styleUrls: ['./candidato-inscricao-ficha.component.css'],
})
export class CandidatoInscricaoFichaComponent implements OnInit {
  eventsSubject: Subject<void> = new Subject<void>()

  sexo = Sexo
  estadoCivil = EstadoCivil
  estado = Estado
  orgaoExpedicaoRg = OrgaoExpedicaoRg
  grauEscolaridade = GrauEscolaridade
  estrangeiro = Estrangeiro
  deficiencia = Deficiencia
  cidades: string[] = []
  temas: string[] = []

  id?: number
  inscricaoFicha?: InscricaoFicha
  tipoConcurso?: TipoConcurso
  permiteEdicao: boolean = false
  temComplemento: boolean = false

  nomeSocial: boolean = false
  nomeMae: boolean = true
  nomePai: boolean = true
  autodeclaracao = Autodeclaracao
  autodeclaraRaca: boolean = false
  declaracaoVeracidade: boolean = false
  pontuacaoDiferenciada: boolean = false
  mostrarCamposAutodeclaracao: boolean = false
  enderecoNacional: boolean = false

  form = new FormGroup({
    idConcurso: new FormControl(),
    nome: new FormControl('', Validators.required),
    dataNascimento: new FormControl('', Validators.required),
    nomeSocial: new FormControl({ value: '', disabled: true }),
    nomeMae: new FormControl('', Validators.required),
    nomePai: new FormControl('', Validators.required),
    profissao: new FormControl('', Validators.required),
    escolaridade: new FormControl('', Validators.required),
    sexo: new FormControl('', Validators.required),
    estadoCivil: new FormControl('', Validators.required),
    estrangeiro: new FormControl('', Validators.required),
    cpf: new FormControl({ value: '', disabled: true }),
    passaporte: new FormControl({ value: '', disabled: true }),
    rne: new FormControl({ value: '', disabled: true }),
    rg: new FormControl({ value: '', disabled: true }),
    uf: new FormControl({ value: '', disabled: true }),
    dataExpedicao: new FormControl({ value: '', disabled: true }),
    orgaoExpedicao: new FormControl({ value: '', disabled: true }),
    pais: new FormControl({ value: '', disabled: true }, Validators.required),
    estado: new FormControl({ value: '', disabled: true }, Validators.required),
    cidade: new FormControl({ value: '', disabled: true }, Validators.required),
    celular: new FormControl('', Validators.required),
    telefone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    enderecoNacional: new FormControl('', Validators.required),
    enderecoCep: new FormControl('', Validators.required),
    enderecoLogradouro: new FormControl('', Validators.required),
    enderecoNumero: new FormControl('', Validators.required),
    enderecoBairro: new FormControl('', Validators.required),
    enderecoCidade: new FormControl('', Validators.required),
    enderecoEstado: new FormControl('', Validators.required),
    enderecoComplemento: new FormControl(''),
    canhoto: new FormControl('', Validators.required),
    autodeclaraRaca: new FormControl(false, Validators.required),
    pontuacaoDiferenciada: new FormControl(false, Validators.required),
    autodeclaracao: new FormControl('NAO_INFORMADO', Validators.required),
    declaracaoVeracidade: new FormControl(false, Validators.requiredTrue),
    possuiDeficiencia: new FormControl('', Validators.required),
    deficiencia: new FormControl(),
    temas: new FormControl(),
  })

  constructor(
    private inscricaoService: InscricaoService,
    private cidadeService: CidadeService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private validacaoService: ValidacaoService,
    private toastService: ToastService,
    private downloadService: DownloadService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.form.reset()
      if (this.id) {
        this.inscricaoService.get(this.id).subscribe(obj => {
          // FIXME: verificar se não é melhor obtem junto com os dados da inscrição
          this.inscricaoService.temas(obj.id).subscribe(temas => {
            this.temas = temas
          })
          this.inscricaoFicha = obj
          this.temComplemento = obj.inscricaoComplementoFase1.length > 0
          this.form.get('idConcurso')?.setValue(obj.idConcurso)
          this.tipoConcurso = obj.tipoConcurso
          this.form.get('nome')?.setValue(obj.nome)
          this.form.get('dataNascimento')?.setValue(this.dataService.formatToBr(obj.dataNascimento))
          this.form.get('nomeSocial')?.setValue(obj.nomeSocial)
          this.form.get('nomeMae')?.setValue(obj.nomeMae)
          this.form.get('nomePai')?.setValue(obj.nomePai)
          this.form.get('profissao')?.setValue(obj.profissao)
          this.form.get('escolaridade')?.setValue(obj.escolaridade)
          this.form.get('sexo')?.setValue(obj.sexo)
          this.form.get('estadoCivil')?.setValue(obj.estadoCivil)
          this.form.get('estrangeiro')?.setValue(obj.estrangeiro)
          this.form.get('cpf')?.setValue(obj.cpf)
          this.form.get('passaporte')?.setValue(obj.passaporte)
          this.form.get('rne')?.setValue(obj.rne)
          this.form.get('rg')?.setValue(obj.rg)
          this.form.get('uf')?.setValue(obj.uf)
          this.form.get('dataExpedicao')?.setValue(this.dataService.formatToBr(obj.dataExpedicao))
          this.form.get('orgaoExpedicao')?.setValue(obj.orgaoExpedicao)
          this.form.get('pais')?.setValue(obj.pais)
          this.form.get('estado')?.setValue(obj.estado)
          this.form.get('cidade')?.setValue(obj.cidade)
          this.form.get('celular')?.setValue(obj.celular)
          this.form.get('telefone')?.setValue(obj.telefone)
          this.form.get('email')?.setValue(obj.email)
          this.form.get('enderecoCep')?.setValue(obj.enderecoCep)
          this.form.get('enderecoLogradouro')?.setValue(obj.enderecoLogradouro)
          this.form.get('enderecoNumero')?.setValue(obj.enderecoNumero)
          this.form.get('enderecoBairro')?.setValue(obj.enderecoBairro)
          this.form.get('enderecoCidade')?.setValue(obj.enderecoCidade)
          this.form.get('enderecoEstado')?.setValue(obj.enderecoEstado)
          this.form.get('enderecoComplemento')?.setValue(obj.enderecoComplemento)
          this.form.get('canhoto')?.setValue(obj.canhoto ? 'true' : 'false')
          if (this.tipoConcurso.match('TECADM')) {
            this.form.get('autodeclaraRaca')?.setValue(obj.autodeclaraRaca ? true : false)
            this.form.get('autodeclaracao')?.setValue(obj.autodeclaracao)
            this.form.get('pontuacaoDiferenciada')?.setValue(obj.pontuacaoDiferenciada ? true : false)
            this.form.get('declaracaoVeracidade')?.setValue(obj.declaracaoVeracidade ? true : false)
          } else {
            this.form.get('autodeclaraRaca')?.disable()
            this.form.get('pontuacaoDiferenciada')?.disable()
            this.form.get('autodeclaracao')?.disable()
            this.form.get('declaracaoVeracidade')?.disable()
          }

          this.form.get('possuiDeficiencia')?.setValue(obj.deficiencia.length ? 'true' : 'false')
          this.form.get('deficiencia')?.setValue(obj.deficiencia)
          this.form.get('temas')?.setValue(obj.temas)
          this.selecionaEstado()
          if (!this.permiteEdicao) {
            this.form.disable()
          }
        })
      }
    })
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  public restrictPhoneInput(event: KeyboardEvent) {
    const allowedChars = /[0-9\+\-\(\)\s]/ // Permite apenas números, +, -, (), espaços
    const key = event.key

    if (!allowedChars.test(key)) {
      event.preventDefault() // Impede a digitação de caracteres inválidos
    }
  }

  public invalidPhoneNumberCel = false
  public invalidPhoneNumberTel = false

  public validatePhoneNumber() {
    let celularValue = this.form.get('celular')?.value || ''
    let telefoneValue = this.form.get('telefone')?.value || ''

    // Remover caracteres que não são permitidos na requisição
    celularValue = celularValue.replace(/[^0-9\+\-\(\)\s]/g, '')
    telefoneValue = telefoneValue.replace(/[^0-9\+\-\(\)\s]/g, '')

    // Regex para validar o número de telefone
    const phoneRegex = /^\+?[1-9]{1,3}?[-.\s]?(\(?\d{1,4}?\))?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/

    // Validar o número e definir a flag
    this.invalidPhoneNumberCel = !phoneRegex.test(celularValue)
    this.invalidPhoneNumberTel = !phoneRegex.test(telefoneValue)

    // Atualiza o valor do campo após limpar caracteres inválidos
    this.form.get('celular')?.setValue(celularValue)
    this.form.get('telefone')?.setValue(telefoneValue)
  }

  gravar(): void {
    if (this.permiteEdicao) {
      this.applyTouchedToInvalidFields()

      if (!this.form.valid) {
        this.toastService.showErrorToast('Atenção', 'O formulário não foi preenchido corretamente')
        return
      }
      if (this.id) {
        const formularioTemporario = this.form.value
        const { enderecoNacional, ...formularioFinal } = formularioTemporario

        //let formulario = <InscricaoFicha>this.form.value
        let formulario = <InscricaoFicha>formularioFinal

        //let formulario = <InscricaoFicha>this.form.value

        this.inscricaoService.alterar(this.id, formulario).subscribe(inscricaoFicha => {
          this.toastService.showSuccessToast('Parabéns', 'Sua inscrição foi realizada com sucesso')
        })
      }
    }
  }

  print(): void {
    window.print()
  }

  validaEstrangeiro(): void {
    this.validacaoService.validaEstrangeiro(this.form)
  }

  validaDeficiencia(): void {
    this.validacaoService.validaDeficiencia(this.form)
  }

  selecionaEstado(): void {
    const estado = this.form.get('estado')?.value
    this.cidades = this.cidadeService.get(estado ?? '')
  }

  validaAutodeclaraRaca(): void {
    this.validacaoService.validaAutodeclaraRaca(this.form)
  }

  onStateChange(): void {
    this.eventsSubject.next()
  }

  validaCEP(enderecoNacional: boolean): void {
    this.validacaoService.validaCEP(this.form, enderecoNacional)
  }

  comprovanteEstudante(): void {
    this.downloadService.comprovanteEstudante(this.inscricaoFicha?.id ?? 0)
  }

  comprovanteRenda(): void {
    this.downloadService.comprovanteRenda(this.inscricaoFicha?.id ?? 0)
  }

  deficienciaLaudoMedico(): void {
    this.downloadService.deficienciaLaudoMedico(this.inscricaoFicha?.id ?? 0)
  }

  save(name: string) {
    localStorage.setItem(name, this.form.get(name)?.getRawValue())
  }

  alteraNomeSocial(): void {
    this.nomeSocial = !this.nomeSocial
    this.validaNomeSocial()
  }

  validaNomeSocial(): void {
    this.validacaoService.validaNomeSocial(this.form, this.nomeSocial)
    localStorage.setItem('chkNomeSocial', this.nomeSocial.toString())
  }

  alteraMaeDesconhecida(): void {
    this.nomeMae = !this.nomeMae
    this.validaMaeDesconhecida()
  }

  validaMaeDesconhecida(): void {
    this.validacaoService.validaMaeDesconhecida(this.form, this.nomeMae)
    localStorage.setItem('chkNomeMae', this.nomeMae.toString())
  }

  alteraPaiDesconhecido(): void {
    this.nomePai = !this.nomePai
    this.validaPaiDesconhecido()
  }

  validaPaiDesconhecido(): void {
    this.validacaoService.validaPaiDesconhecido(this.form, this.nomePai)
    localStorage.setItem('chkNomePai', this.nomePai.toString())
  }

  alteraTipoEndereco(): void {
    this.enderecoNacional = this.form.controls['enderecoNacional'].value === 'true' ? true : false
  }
}
