import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EMPTY, Observable } from 'rxjs'
import { UsuarioService } from '../services/usuario.service'

@Injectable({
  providedIn: 'root',
})
export class TokenHttpInterceptor implements HttpInterceptor {
  constructor(public usuarioService: UsuarioService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('sinsc-candidato-token')
    if (!token || req.url.includes('/publico/')) {
      return next.handle(req)
    }
    const expiry = JSON.parse(atob(token!.split('.')[1])).exp
    const now = Math.floor(new Date().getTime() / 1000)
    const expired: boolean = now >= expiry
    if (expired) {
      this.usuarioService.timeout()
      return EMPTY
    } else {
      return next.handle(
        req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token), withCredentials: false })
      )
    }
  }
}
