import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'
import { ConcursoAndamento } from 'src/app/models/concurso-andamento'
import { ConcursoService } from 'src/app/services/concurso.service'
import { DownloadService } from 'src/app/services/download.service'
import { Meta } from '@angular/platform-browser'
import { ToastService } from '../../toast/services/toast.service'

@Component({
  selector: 'app-concurso',
  templateUrl: './concurso.component.html',
  styleUrls: ['./concurso.component.css'],
})
export class ConcursoComponent implements OnInit {
  id?: number
  concursoAndamento?: ConcursoAndamento
  loading: boolean = true

  constructor(
    private concursoService: ConcursoService,
    private downloadService: DownloadService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private meta: Meta,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.id = Number(params['id'])
      let caracteresEspeciais = params['id'].replace(/[\d\s]/g, '')

      if (this.id && typeof this.id === 'number' && Number.isInteger(this.id) && caracteresEspeciais.length == 0) {
        this.concursoService.get(this.id).subscribe(obj => {
          if (obj) {
            this.meta.updateTag({ name: 'description', content: obj.titulo })
            this.concursoAndamento = obj
            this.loading = false
          } else {
            setTimeout(() => {
              this.router.navigate(['/concurso/inscricao-aberta'])
            }, 2000)
          }
        })
      } else {
        this.toastService.showErrorToast('Atenção', 'URL inválida')
        setTimeout(() => {
          this.voltar()
        }, 2000)
      }
    })
  }

  voltar(): void {
    this.location.back()
  }

  edital(): void {
    this.downloadService.edital(this.concursoAndamento?.id ?? 0)
  }
}
