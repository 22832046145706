import { Component, OnInit } from '@angular/core'
import { Concurso } from 'src/app/models/concurso'
import { ConcursoService } from 'src/app/services/concurso.service'
import { PaginationBaseComponent } from '../../pagination/components/pagination-base.component'

@Component({
  selector: 'app-lista-inscricao-encerrada',
  templateUrl: './lista-inscricao-encerrada.component.html',
  styleUrls: ['./lista-inscricao-encerrada.component.css'],
})
export class ListaInscricaoEncerradaComponent extends PaginationBaseComponent implements OnInit {
  concursos: Concurso[] = []
  input: string = ''
  private _search: string = ''
  private _searchClicked: boolean = true

  constructor(private concursoService: ConcursoService) {
    super()
  }

  ngOnInit(): void {
    this.getData()
  }

  search(): void {
    this._search = this.input.trim().toLowerCase()
    this._searchClicked = true
    this.getData()
  }

  override getData(): void {
    let filter: any =
      this._search === ''
        ? { page: this.current - 1, size: this.perPage }
        : this._searchClicked
        ? { search: this._search }
        : { page: this.current - 1, size: this.perPage, search: this._search }
    this._searchClicked = false
    this.concursoService.inscricoesEncerradas(filter).subscribe(data => {
      this.concursos = data['content']
      this.totalPages = data['totalPages']
      this.totalElements = data['totalElements']
    })
  }
}
