import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { identity, Observable } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { ConcursoAndamento } from '../models/concurso-andamento'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ConcursoService {
  private baseUrl: string

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {
    this.baseUrl = environment.baseUrlApi
  }

  inscricoesAbertas(filter: any): Observable<any> {
    const params = filter
    return this.http
      .get<any>(`${this.baseUrl}/publico/concurso`, { params })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  inscricoesEmAndamento(filter: any): Observable<any> {
    const params = filter
    return this.http
      .get<any>(`${this.baseUrl}/publico/concurso/em-andamento`, { params })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  inscricoesProximas(filter: any): Observable<any> {
    const params = filter
    return this.http
      .get<any>(`${this.baseUrl}/publico/concurso/proximos`, { params })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  inscricoesEncerradas(filter: any): Observable<any> {
    const params = filter
    return this.http
      .get<any>(`${this.baseUrl}/publico/concurso/encerrados`, { params })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  get(id: number): Observable<ConcursoAndamento> {
    return this.http
      .get<ConcursoAndamento>(`${this.baseUrl}/publico/concurso/${id}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
