<fieldset class="border rounded-3 p-2">
  <legend class="w-auto">Inscrição</legend>
  <div class="row">
    <div class="col-sm-12" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">Câmpus</span>
        <input readonly="readonly" class="form-control" value="{{ inscricaoFicha?.campus }}" type="text" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">Concurso</span>
        <input readonly="readonly" class="form-control" value="{{ inscricaoFicha?.tituloConcurso }}" type="text" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">Período de inscrição</span>
        <input
          readonly="readonly"
          class="form-control"
          value="{{ inscricaoFicha?.inicioConcurso | date : 'dd/MM/yyyy' }} até {{
            inscricaoFicha?.fimConcurso | date : 'dd/MM/yyyy'
          }} às {{ inscricaoFicha?.horaFimConcurso?.substring(0, 5) }} h"
          type="text" />
      </div>
    </div>
  </div>
  <div class="row" *ngIf="inscricaoFicha?.inicioFase2 && inscricaoFicha?.fimFase2">
    <div class="col-sm-12" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">Período da segunda fase</span>
        <input
          readonly="readonly"
          class="form-control"
          value="{{ inscricaoFicha?.inicioFase2 | date : 'dd/MM/yyyy' }} até {{
            inscricaoFicha?.fimFase2 | date : 'dd/MM/yyyy'
          }} às 23:59 h"
          type="text" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">Protocolo</span>
        <input readonly="readonly" class="form-control" value="{{ inscricaoFicha?.protocolo }}" type="text" />
      </div>
    </div>
    <div class="col-sm-3" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">Data</span>
        <input
          readonly="readonly"
          class="form-control"
          value="{{ inscricaoFicha?.data | date : 'dd/MM/yyyy HH:mm:ss' }}"
          type="text" />
      </div>
    </div>
    <div class="col-sm-3" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">Pagamento</span>
        <input
          readonly="readonly"
          class="form-control"
          value="{{ inscricaoFicha?.pago ? 'Confirmado' : 'Pendente' }}"
          type="text" />
      </div>
    </div>
  </div>
  <div class="row" *ngIf="inscricaoFicha?.status !== 'Não confirmada'">
    <div class="col-sm-12" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">Situação da inscrição</span>
        <input readonly="readonly" class="form-control" value="{{ inscricaoFicha?.status }}" type="text" />
      </div>
    </div>
  </div>
</fieldset>
